
jQuery ($) ->


  App =
    grid: $('.js-rea-grid')
    filters: ''

    init: ->
      # Init everything needed
      App.bind_events()


    bind_events: ->
      # Move services submenu
      services_menu = $('.js-add-services-submenu')
      if services_menu.length
        services_menu.append $('.js-submenu[data-menu="services"]')


      # Isotope: filtering using URL hash (realisations)
      # Bind filter button click
      App.filters = $('#filters').on 'click', 'button', ->
        filterAttr = $( this ).attr('data-filter')
        # Set filter in hash
        location.hash = 'filter=' + encodeURIComponent(filterAttr)

      isIsotopeInit = false

      $(window).on 'hashchange', App.onHashchange
      # Trigger event handler to init Isotope
      App.onHashchange()

      # Toggle the mobile menu
      $('#js-toggle-menu').on 'click', (e) ->
        e.preventDefault()
        $('header #headMenu, #js-toggle-menu').toggleClass 'is--active'

      # Services - toggle gallery
      $('.gallerieButton').each (id, elem) ->
        gal_id = $(this).attr 'id'
        if $('#panel' + gal_id).find('.showGal').length
          # Open gallery
          $(elem).on 'click', (e) ->
            this_id = $(this).attr 'id'
            panel_gal = $('#panel' + this_id).find '.showGal.slick-initialized'
            $('.blocSlideGal, .textSlideNum, .textSlide, .bgOppo').slick 'slickPause'
            $('#panel' + this_id).addClass 'active'
            panel_gal[0].slick.refresh() if panel_gal.length
            $('header, footer').css
              'z-index': -1
            $('body').addClass 'is--frozen'
          # Close gallery
          $('#panel' + gal_id).find('.panelClose').on 'click', (e) ->
            $('.panelGallerieButton').removeClass 'active'
            $('.blocSlideGal, .textSlideNum, .textSlide, .bgOppo').slick 'slickPlay'
            $('header, footer').css
              'z-index': 10
            $('body').removeClass 'is--frozen'
        else
          $(elem).addClass 'is--empty'


      # Contact - preselect subject regarding URL hash
      if $('#formCont').length
        service_id = App.extractUrlValue 'sujet'
        $('#input_3_3').val service_id


    extractUrlValue: (key, url) ->
      if typeof(url) == 'undefined'
        url = window.location.href
      match = url.match '[?&]' + key + '=([^&#]+)'
      return if match then match[1] else null


    onHashchange: ->
      hashFilter = App.getHashFilter()
      return if (!hashFilter && isIsotopeInit)
      isIsotopeInit = true

      # Filter isotope
      App.grid.isotope
        itemSelector: '.element-item'
        layoutMode: 'packery'
        packery:
          gutter: 0
        filter: hashFilter

      # Set selected class on button
      if hashFilter
        App.filters.find('.is-checked').removeClass 'is-checked'
        App.filters.find('[data-filter="' + hashFilter + '"]').addClass 'is-checked'


    getHashFilter: ->
      hash = location.hash
      # get filter=filterName
      matches = location.hash.match(/filter=([^&]+)/i)
      hashFilter = matches && matches[1]
      return hashFilter && decodeURIComponent(hashFilter)


  # Init
  $ ->
    App.init()